@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.ask-question-card[data-v-b431e254] {
  position: relative;
  background-color: #c8c9ba;
  color: #f3594c;
  min-height: 260px;
  padding: 0 14px;
}
@media (max-width: 576px) {
.ask-question-card[data-v-b431e254] {
      width: 100vw;
      height: 90vh;
      margin: 0 auto;
}
}
.ask-question-card a[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: inherit;
    text-decoration: none;
}
@media (max-width: 576px) {
.ask-question-card a[data-v-b431e254] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 90%;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
}
}
.ask-question-card a[data-v-b431e254]:hover {
      color: #fff;
}
.ask-question-card a:hover h2[data-v-b431e254] {
        color: #fff;
}
.ask-question-card-header[data-v-b431e254] {
    margin: 0 auto;
    color: #fff;
}
.ask-question-card-header-image[data-v-b431e254] {
      height: 240px;
      text-align: center;
      vertical-align: bottom;
}
@media (max-width: 576px) {
.ask-question-card-header-image[data-v-b431e254] {
          margin-top: 36px;
          height: 160px;
}
}
.ask-question-card-header-image svg[data-v-b431e254] {
        height: 100%;
}
.ask-question-card-body[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 36px;
}
@media (max-width: 576px) {
.ask-question-card-body[data-v-b431e254] {
        padding: 0 19px 19px 19px;
}
}
.ask-question-card-title h2[data-v-b431e254] {
    font-size: 36px;
    line-height: 1.2;
}
.ask-question-card-content[data-v-b431e254] {
    font-size: 19px;
    line-height: 1.2;
}
.ask-question-card-link[data-v-b431e254] {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
}
.ask-question-card-link svg[data-v-b431e254] {
      height: 80px;
}


/*# sourceMappingURL=base~mentor.358dcc32.css.map*/